import React, { useState } from 'react';
import { styled } from '../../../stitches.config';
import Box from '../Box';

export default props => {
    return (
        <section className="container">
            <Box {...props}
                id="b1"
                type="BOnnetworkWidget"
                settings={props.settings.b1}
            />
        </section>
    );
}

